/*
 * @Author: Leeon 282276848@qq.com
 * @Date: 2024-03-28 16:39:34
 * @LastEditors: Leeon 282276848@qq.com
 * @LastEditTime: 2024-03-28 17:43:29
 * @FilePath: \rsk-h5\src\mixinx\agreementNext.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as API_Common from "@/api/common.js";
export const agreementNext = {
  methods: {
    // 提交表单后的下一步页面跳转
    async submitPushApply() {
      if (this.redirectUrl) {
        // 跳转到三方页面
        window.open(this.redirectUrl);
        return;
      }

      if (this.hasPushSuccess === 0) {
        this.gotoNextPage(false);
        return;
      }

      this.loading = true;

      // 调用推送进件接口
      try {
        var res = await API_Common.pushApply({
          applyId: this.applyId,
          productId: this.productId,
        });
        this.loading = false;
        if (res.code === 200) {
          const hasPushSucces = res.data.hasPushSuccess;
          this.gotoNextPage(hasPushSucces);
        } else {
          this.gotoNextPage(false);
        }
      } catch (err) {
        this.loading = false;
        this.gotoNextPage(false);
      }
    },
    //跳转下一个页面
    gotoNextPage(hasPushSuccess) {
      if (hasPushSuccess) {
        var timestamp = new Date().getTime();
        // 跳转到成功结果页, 不管什么贷超流程
        window.location.href =
          `${this.downPageUrl}?source=${this.form.channelNo}&brand=${this.brand}&loanAmount=${this.form.loanAmount}&applyId=${this.applyId}&isRedirectProductsPage=${this.isRedirectProductsPage}` +
          "&tsp=" +
          timestamp;
        return;
      }

      if (this.isRedirectProductsPage === 1) {
        // 跳转到贷超
        setTimeout(() => {
          var timestamp = new Date().getTime();
          window.location.href =
            `/productList.html?source=${this.form.channelNo}` +
            "&tsp=" +
            timestamp;
        }, 1500);
      } else {
        // 跳转到失败结果页
        // 非贷超情况跳转兜底下载页面
        setTimeout(() => {
          this.$router.push({
            name: "TrialSuccessTl5",
            query: {
              source: this.form.channelNo,
              loanAmount: this.form.loanAmount,
            },
          });
        }, 1500);
      }
    },
    // 我已知晓下一步
    handleGoOn() {
      if (this.loading) {
        return;
      }
      this.submitPushApply();
    },
  },
};
